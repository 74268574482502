import CONSTANTS from '@/common/constants';
import { localStorage } from '@/common/utils';

export const handleInstallPopupVisibility = ({
  type,
  linkedAddress,
  setShowInstallAppPopup,
}) => {
  const counterNavigate = JSON.parse(
    localStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.NAVIGATE_POPUP_COUNTER),
  );
  const condition = type === 'wallet' && counterNavigate < 1 && linkedAddress;

  setShowInstallAppPopup(condition);
  if (condition) {
    const counter = JSON.parse(
      localStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.NAVIGATE_POPUP_COUNTER),
    );
    localStorage.setItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.NAVIGATE_POPUP_COUNTER,
      JSON.stringify(counter + 1),
    );
  }
};
