import { navigate } from 'gatsby-link';
import React, { useEffect, useRef, useState } from 'react';
import { QRCode } from 'react-qrcode-logo';

import MetamaskButton from '@//components/metamask-button/metamask-button';
import { useLinkedAddress, useWalletSummary } from '@/api/api-hooks';
import CONSTANTS from '@/common/constants';
import { generateAppInstallURL, mobileAndTabletCheck } from '@/common/helpers';
import { isBrowser } from '@/common/utils';
import Banner from '@/components/banner';
import DashboardLayout from '@/components/layout/dashboard-layout';
import Modal, { ModalRef } from '@/components/modal/modal';
import { useAuth } from '@/context/auth-context';

import BackButton from '../collectibles-marketplace/components/back-button';

import TokenList from './components/token-list';
import WalletSummary from './components/wallet-summary';
import { handleInstallPopupVisibility } from './handle-install-popup-visibility';
// import BackButton from '../deposit-withdraw/withdraw/components/back-button';

const WalletPage = () => {
  const { user, initialSearchParamsRef } = useAuth();
  const { data: walletSummaryData, jrWalletBalance } = useWalletSummary();
  const { linkedAddress } = useLinkedAddress(user?.appUser.user.id);
  const qrCodeModalRef = useRef<ModalRef>(null);
  const congratsModalRef = useRef<ModalRef>(null);
  const [showInstallAppPopup, setShowInstallAppPopup] = useState(false);

  useEffect(() => {
    const counter = JSON.parse(
      localStorage.getItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.NAVIGATE_POPUP_COUNTER,
      ) as string,
    );
    if (counter === null) {
      localStorage.setItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.NAVIGATE_POPUP_COUNTER,
        JSON.stringify(0),
      );
    }
  }, []);

  useEffect(() => {
    if (showInstallAppPopup) {
      congratsModalRef.current?.openModal();
    } else {
      congratsModalRef.current?.closeModal();
    }
  }, [showInstallAppPopup, jrWalletBalance]);

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      if (linkedAddress) {
        handleInstallPopupVisibility({
          type: 'wallet',
          linkedAddress,
          setShowInstallAppPopup,
        });
      }
    }, 5000);

    return () => {
      clearTimeout(timeoutRef);
    };
  }, [linkedAddress, setShowInstallAppPopup]);

  const handleClose = () => {
    navigate('');
  };

  const handleBannerClick = () => {
    if (mobileAndTabletCheck()) {
      if (isBrowser()) {
        window.location.href = generateAppInstallURL(initialSearchParamsRef);
      }
    } else {
      qrCodeModalRef.current?.openModal();
    }
  };

  useEffect(() => {
    navigate('/marketplace');
  }, []);

  return (
    <></>
  );
};

export default WalletPage;
