import React from 'react';

import { WalletPage } from '@/features/wallet';

export default WalletPage;

export function Head() {
  return (
    <>
      <title>SuperChamps - Wallet</title>
      <script
        src="https://cdn.withpersona.com/dist/persona-v4.11.0.js"
        id="persona"
      ></script>
    </>
  );
}
